import { React } from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useMatch } from 'react-router-dom';

import { responsiveBreakpoints } from 'utils/utils';
import TextField from 'components/text-field';
import ApplyFiltersModal from 'components/apply-filters-modal/apply-filters-modal';
import AlphaSort from 'components/modals/alphasort';
import FilterIcon from 'assets/icons/filter-icon';
import SearchIconVariant from 'assets/icons/search-icon-variant';
import useFilterOptions from './filter-options-hooks';
import MultiSelect from 'components/select/multi-select';

export default function FilterOptions({
  applyFilters,
  buyer,
  clearFilters,
  showAlphaSort,
  showBuyersComponent,
  handleGlobalSearchValue,
  globalSearchValue,
}) {
  const {
    showApplyFiltersModal,
    setShowApplyFiltersModal,
    filterCount,
    setFilter,
    showBuyers,
    supportData,
    filters,
    setFilters,
    updateURLParams,
    srch,
    retailerAlphabetValue,
    handleSubmit,
  } = useFilterOptions({ applyFilters });

  const { mobile } = responsiveBreakpoints();

  return (
    <Stack spacing={1}>
      <Stack
        direction='row'
        alignItems='end'
        spacing={8}
        sx={{ display: 'flex' }}
      >
        {showApplyFiltersModal ? (
          <ApplyFiltersModal
            open={showApplyFiltersModal}
            setOpen={setShowApplyFiltersModal}
            filtersToEnable={{
              salesRep: true,
              priceGroup: true,
              paymentTerms: buyer ? false : true,
              shippingMethod: buyer ? false : true,
              sortBy: buyer ? false : true,
              showRetailersAndBuyersWith: buyer ? false : true,
              groups: buyer ? false : true,
              currency: buyer ? true : false,
              showBuyers: buyer ? true : true,
            }}
            filters={filters}
            setFilters={setFilters}
            applyFilters={applyFilters}
          />
        ) : null}

        <Stack
          direction='row'
          gap={1}
          alignItems='center'
          sx={{ display: 'flex', flexGrow: '1' }}
        >
          <Box sx={{ flexGrow: '1', height: '34px' }}>
            <TextField
              // value={searchValue}
              value={globalSearchValue}
              onChange={(e) => {
                handleGlobalSearchValue(e.target.value);
              }}
              placeholder='Search'
              startAdornment={<SearchIconVariant />}
              sx={{
                width: '100%',
                paddingLeft: '10px',
                minWidth: mobile ? 180 : 240,
                height: '34px',
                backgroundColor: globalSearchValue ? '#cfe2ff' : '',
              }}
            />
          </Box>

          {clearFilters ? (
            <Button
              variant='contained'
              onClick={clearFilters}
              color='error'
              size='small'
              fontStyle='bold'
              fontWeight='700'
              sx={{ cursor: 'pointer', textWrap: 'nowrap' }}
            >
              Clear Search Filters
            </Button>
          ) : null}
        </Stack>
        <Stack direction='row' spacing={1} alignItems='end'>
          {showBuyersComponent ? (
            <Box
              sx={{
                width: 'fit-content',
                display: 'flex',
                flexDirection: 'row',
                columnGap: '8px',
                alignItems: 'center',
              }}
            >
              <Typography
                variant='body1'
                fontWeight='500'
                sx={{ whiteSpace: 'nowrap' }}
              >
                View By
              </Typography>
              <MultiSelect
                label=''
                labelAccessor='name'
                value={filters?.showBuyers}
                placeholder='Activity'
                valueAccessor='id'
                setValue={(value) => {
                  setFilters((prev) => ({ ...prev, ['showBuyers']: value }));
                  let tempObj = { ...filters, ['showBuyers']: value };
                  handleSubmit({ filterString: tempObj });
                }} // Using setFilter for updating state
                options={supportData?.showBuyers}
                sx={{ height: '34px', width: '115px' }}
              />
            </Box>
          ) : null}
          <Button
            size='small'
            variant='outlined'
            color={filterCount > 0 ? 'primary' : 'secondary'}
            startIcon={<FilterIcon />}
            onClick={(e) => {
              e.preventDefault();
              setShowApplyFiltersModal(!showApplyFiltersModal);
            }}
            sx={[
              { height: 'fit-content', padding: '7px 9px' },
              filterCount > 0 ? { backgroundColor: '#fff' } : null,
            ]}
          >
            <Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
              <Typography>Filters</Typography>
              {filterCount > 0 ? (
                <Box
                  sx={{
                    minWidth: '20px',
                    aspectRatio: '1/1',
                    padding: '4px',
                    borderRadius: '50%',
                    backgroundColor: 'primary.main',
                    color: 'var(--contained-primary-button-text-color)',
                    fontSize: '12px',
                    lineHeight: '12px',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant='body1'
                    sx={{
                      color: 'var(--contained-primary-button-text-color)',
                      fontSize: '12px',
                      lineHeight: '12px',
                    }}
                  >
                    {filterCount}
                  </Typography>
                </Box>
              ) : null}
            </Stack>
          </Button>
        </Stack>
      </Stack>
      {showAlphaSort ? (
        <AlphaSort
          alphabetValue={retailerAlphabetValue}
          setAlphabetValue={(val) => {
            if (val !== '' && retailerAlphabetValue !== val) {
              updateURLParams({
                srch: '',
                retailerAlphabetValue: val,
                retailerPageValue: '1',
              });
            }
          }}
        />
      ) : null}
    </Stack>
  );
}

FilterOptions.propTypes = {
  applyFilters: PropTypes.func.isRequired,
  buyer: PropTypes.bool,
  clearFilters: PropTypes.func,
  showAlphaSort: PropTypes.bool,
  showBuyersComponent: PropTypes.bool,
  handleGlobalSearchValue: PropTypes.func,
  globalSearchValue: PropTypes.string,
};
FilterOptions.defaultProps = {
  buyer: false,
  showAlphaSort: false,
  showBuyersComponent: false,
  handleGlobalSearchValue: () => {},
  globalSearchValue: '',
};
