import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';
import SelectBuyer from './components/select-buyers';
import SendMessage from './components/send-message';
import Review from './components/review';
import useSendEmail from './send-email-hook';
import SendingEmailsModal from './components/sending-emails-modal';
import SelectOld from 'components/select/select.old';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSelector } from 'react-redux';
import { loggedInUserSelector } from 'redux/selectors';
import Page from 'components/page';
import SendEmailActions from './components/sendEmail-actions';
import AlertModal from 'components/modals/alert/alert-modal';
import { showSnackbar } from 'components/snackbar/actions';
import UploadIcon from 'assets/icons/upload-icon';
import Chip from '@mui/material/Chip';
import LoadingButton from '@mui/lab/LoadingButton';
import { responsiveBreakpoints } from 'utils/utils';
import downloadImage from 'assets/images/download_image.png';
import AttachFileIcon from 'assets/icons/attach-file-icon';
import InformationIcon from 'assets/icons/information-icon';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import Confirm from 'components/modals/confirmation-modal';

export default function SendEmail({
  open,
  setOpen,
  worksheetId,
  worksheetCollectionId,
  suggestionId,
  orderLink,
}) {
  const {
    selectedBuyers,
    setSelectedBuyers,
    selectedAssociates,
    setSelectedAssociates,
    subject,
    setSubject,
    editorValue,
    setEditorValue,
    message,
    setMessage,
    timeline,
    setTimeline,
    submit,
    emailRequests,
    sendingEmails,
    setSendingEmails,
    client,
    sender,
    setSender,
    showInventory,
    setShowInventory,
    uploadImage,
    selectedSender,
    setSelectedSender,
    selectedSignature,
    setSelectedSignature,
    subjectEmptyError,
    setSubjectEmptyError,
    showPreview,
    setShowPreview,
    showSendingEmailAlertModal,
    suggestionPage,
    setShowSendingEmailAlertModal,
    draftLink,
    sendinviteEmail,
    isBuyersListExceeded,
    setIsBuyersListExceeded,
    sendEmail,
    pageDetails,
    isAllBuyerSelected,
    setIsAllBuyerSelected,
    groups,
    setGroups,
    salesRep,
    setSalesRep,
    messageEmptyError,
    setMessageEmptyError,
    isSelectAll,
    setIsSelectAll,
    emailValue,
    setEmailValue,
    totalBuyersSelected,
    setTotalBuyersSelected,
    isLoading,
    setIsLoading,
    onFirstTimeLoad,
    setOnFirstTimeLoad,
    getPreviewEmailContent,
    navigate,
    setMessageAndSubject,
    previewMessage,
    previewSubject,
    suggestionEmailpayloadData,
    draftLinkPayloadData,
    uploadFileLoader,
    setUploadFileLoader,
    selectedFiles,
    setSelectedFiles,
    uploadImageLoader,
    setUploadImageLoader,
    getPreviewLoader,
    hasBannerImage,
    attachedFileCount,
    isError,
    setIsError,
    fileLimit,
    isCopyMeSelected,
    setIsCopyMeSelected,
    selectedExternalContacts,
    setSelectedExternalContacts,
    showSuggestionWarningModal,
    setShowSuggestionWarningModal,
  } = useSendEmail({
    setOpen,
    worksheetCollectionId,
  });
  const { mobile } = responsiveBreakpoints();
  const dispatch = useDispatch();
  const nextStep = (
    <Button
      variant='contained'
      onClick={(e) => {
        window.scrollTo(0, 0);
        e.preventDefault();
        if (user.roleId === '3' && timeline === 1) {
          setTimeline(timeline + 2);
        } else {
          setTimeline(timeline + 1);
        }
      }}
    >
      Next Step
    </Button>
  );

  const sendLink = (
    <Button
      variant='contained'
      onClick={async (e) => {
        setShowPreview(false);
        if (suggestionPage) {
          setShowPreview(false);
          setShowSuggestionWarningModal(true);
        } else {
          if (isBuyersListExceeded) {
            setIsLoading(true);
            let response = await sendEmail();
            if (response?.data?.status) {
              setIsLoading(false);
              setShowPreview(false);
              setShowSendingEmailAlertModal(true);
            } else {
              setIsLoading(false);
              dispatch(
                showSnackbar({
                  snackbarMessage: 'Failed To Send Email',
                  type: 'error',
                  snackbarAlign: {
                    vertical: 'top',
                    horizontal: 'right',
                  },
                })
              );
            }
          } else {
            submit();
          }
        }

        // setSendingEmails(true);
        // setShowSendingEmailAlertModal(true);
        // handleClose();
      }}
    >
      Send
    </Button>
  );
  const suggestionWarningModalProceed = (
    <Button
      variant='contained'
      onClick={async (e) => {
        setShowSuggestionWarningModal(false);
        if (isBuyersListExceeded) {
          setIsLoading(true);
          let response = await sendEmail();
          if (response?.data?.status) {
            setIsLoading(false);
            setShowPreview(false);
            setShowSendingEmailAlertModal(true);
          } else {
            setIsLoading(false);
            dispatch(
              showSnackbar({
                snackbarMessage: 'Failed To Send Email',
                type: 'error',
                snackbarAlign: {
                  vertical: 'top',
                  horizontal: 'right',
                },
              })
            );
          }
        } else {
          submit();
        }

        // setSendingEmails(true);
        // setShowSendingEmailAlertModal(true);
        // handleClose();
      }}
    >
      Yes, Proceed
    </Button>
  );
  const back = (
    <Button
      variant='outlined'
      color='secondary'
      onClick={(e) => {
        e.preventDefault();
        setTimeline(timeline - 1);
      }}
    >
      Back
    </Button>
  );
  const preview = (
    <LoadingButton
      loading={getPreviewLoader}
      disabled={
        getPreviewLoader ||
        selectedBuyers.length > 0 ||
        (suggestionPage ? selectedExternalContacts.length > 0 : false) ||
        (sendinviteEmail ? false : selectedAssociates.length > 0)
          ? false
          : true
      }
      variant='contained'
      onClick={() => {
        if (
          subject == null ||
          subject.trim() == '' ||
          message.trim() === '<p></p>'.trim()
        ) {
          if (message.trim() === '<p></p>'.trim()) {
            setMessageEmptyError(true);
          } else {
            setMessageEmptyError(false);
          }
          if (subject == null || subject.trim() == '') {
            setSubjectEmptyError(true);
          } else {
            setSubjectEmptyError(false);
          }
          return;
        } else {
          setSubjectEmptyError(false);
          setMessageEmptyError(false);
          if (draftLink || sendinviteEmail || suggestionPage)
            getPreviewEmailContent();
          else setShowPreview(true);
        }
      }}
    >
      Preview & Send
    </LoadingButton>
  );
  // logic to make BuyersList Exceede
  useEffect(() => {
    if (selectedBuyers.length > 15) {
      setIsBuyersListExceeded(true);
    } else {
      if (isBuyersListExceeded) {
        setIsBuyersListExceeded(false);
      }
    }
  }, [selectedBuyers]);
  const user = useSelector(loggedInUserSelector);
  return (
    <Page
      heading={pageDetails?.title}
      backButtonText={pageDetails?.backButtonText}
      backButtonHref={pageDetails?.backButtonUrl}
    >
      {isLoading ? (
        <Box
          width='100vw'
          height='100vh'
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 9999,
            opacity: 0.4,
            backgroundColor: '#000',
          }}
        >
          <CircularProgress />
        </Box>
      ) : null}
      <SendingEmailsModal
        open={sendingEmails}
        setOpen={(bool) => {
          if (!bool) {
            navigate(pageDetails?.backButtonUrl);
            setSendingEmails(bool);
          }
        }}
        emailRequests={emailRequests}
        openParentModal={open}
        setOpenParentModal={setOpen}
        showInventory={showInventory}
        selectedSender={selectedSender}
        selectedSignature={selectedSignature}
      />
      {showSuggestionWarningModal ? (
        <Confirm
          open={showSuggestionWarningModal}
          setOpen={setShowSuggestionWarningModal}
          action={suggestionWarningModalProceed}
          title='Are you sure?'
          message={
            <Typography
              variant='h3'
              sx={{
                fontWeight: 500,
                color: '#333333BF',
              }}
            >
              One or more buyers do not have visibility to certain products in
              your suggestion list. Are you sure you want to proceed?
            </Typography>
          }
        />
      ) : null}
      {showSendingEmailAlertModal ? (
        <AlertModal
          open={showSendingEmailAlertModal}
          setOpen={setShowSendingEmailAlertModal}
          title='Sending emails to selected buyers…'
          message="Sit back and relax and keep an eye on the bell icon, you'll receive a notification as soon as all the emails are sent."
          type='email'
          disableBackdropClose={true}
          hideCloseIcon={true}
          handleOkClick={() => {
            navigate(pageDetails.backButtonUrl);
          }}
        />
      ) : null}
      {isError?.open ? (
        <AlertModal
          type='warning'
          title={isError?.message}
          open={isError?.open}
          setOpen={(bool) => {
            setIsError({
              open: bool,
              message: bool ? 'File upload limit is 3' : '',
            });
          }}
        />
      ) : null}

      <Stack sx={{ padding: '0px' }}>
        <Timeline
          sx={{
            padding: '0px',
            marginTop: '0px',
            [`& .css-ha3bif-MuiTimelineItem-root:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot
                color='primary'
                sx={{
                  height: '36px',
                  width: '36px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setTimeline(1);
                }}
              >
                {timeline > 1 ? (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='14'
                    height='14'
                    viewBox='0 0 14 14'
                    fill='#fff'
                  >
                    <g
                      id='_38_Question'
                      data-name='38 Question'
                      transform='translate(-0.005 -0.003)'
                    >
                      <path
                        id='Path_20782'
                        data-name='Path 20782'
                        d='M.985,14a.983.983,0,0,1-.972-1.11L.365,10.21a1.116,1.116,0,0,1,.316-.642L9.931.318a1.078,1.078,0,0,1,1.522,0L13.69,2.555a1.078,1.078,0,0,1,0,1.522l-9.25,9.25a1.115,1.115,0,0,1-.642.316l-2.683.351A.993.993,0,0,1,.985,14Zm.723-3.53-.275,2.1,2.1-.275,8.983-8.983L10.692,1.49Z'
                      />
                      <path
                        id='Path_20783'
                        data-name='Path 20783'
                        d='M283.16,84.283a.682.682,0,0,1-.483-.2l-2.794-2.794a.684.684,0,0,1,.968-.967l2.793,2.793a.684.684,0,0,1-.483,1.167Z'
                        transform='translate(-272.03 -77.929)'
                      />
                    </g>
                  </svg>
                ) : (
                  <Typography
                    variant='h2'
                    sx={{ color: 'white', fontSize: '16px' }}
                  >
                    1
                  </Typography>
                )}
              </TimelineDot>

              <TimelineConnector
                sx={{ backgroundColor: '#e3e7ed', opacity: '0.7' }}
              />
            </TimelineSeparator>
            <TimelineContent
              sx={{
                flex: 500,
                paddingLeft: mobile ? '8px' : '30px',
                paddingRight: '0px',
                paddingTop: '20px',
                width: '80%',
              }}
            >
              <Stack spacing={1} sx={{ width: '100%' }}>
                <Typography variant='h2' sx={{ fontWeight: '900' }}>
                  Select Recipients
                </Typography>
                {/* <Stack sx={[timeline === 1 ? { display: 'block' } : { display: 'none' }]}><SelectBuyer /></Stack> */}
                {timeline === 1 && (
                  <Stack>
                    <SelectBuyer
                      selectedBuyers={selectedBuyers}
                      setSelectedBuyers={setSelectedBuyers}
                      worksheetId={worksheetId}
                      sendinviteEmail={sendinviteEmail}
                      selectedAssociates={selectedAssociates}
                      setSelectedAssociates={setSelectedAssociates}
                      selectedSender={selectedSender}
                      setSelectedSender={setSelectedSender}
                      selectedSignature={selectedSignature}
                      setSelectedSignature={setSelectedSignature}
                      showComponents={{
                        addContactsComponent: suggestionPage ? true : false,
                        copyAssociateComponent:
                          sendinviteEmail || user?.roleId === '3'
                            ? false
                            : true,
                        senderSignatureComponent: sendinviteEmail
                          ? true
                          : false,
                      }}
                      isAllBuyerSelected={isAllBuyerSelected}
                      setIsAllBuyerSelected={setIsAllBuyerSelected}
                      groups={groups}
                      setGroups={setGroups}
                      salesRep={salesRep}
                      setSalesRep={setSalesRep}
                      isSelectAll={isSelectAll}
                      setIsSelectAll={setIsSelectAll}
                      emailValue={emailValue}
                      setEmailValue={setEmailValue}
                      setTotalBuyersSelected={setTotalBuyersSelected}
                      setMessageAndSubject={setMessageAndSubject}
                      selectedExternalContacts={selectedExternalContacts}
                      setSelectedExternalContacts={setSelectedExternalContacts}
                    />
                  </Stack>
                )}
              </Stack>
            </TimelineContent>
          </TimelineItem>

          {/* second item  */}
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot
                color={timeline < 2 ? 'secondary' : 'primary'}
                variant={timeline < 2 ? 'outlined' : 'filled'}
                sx={{
                  height: '36px',
                  width: '36px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setTimeline(2);
                }}
              >
                {timeline > 2 ? (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='14'
                    height='14'
                    viewBox='0 0 14 14'
                    fill='#fff'
                  >
                    <g
                      id='_38_Question'
                      data-name='38 Question'
                      transform='translate(-0.005 -0.003)'
                    >
                      <path
                        id='Path_20782'
                        data-name='Path 20782'
                        d='M.985,14a.983.983,0,0,1-.972-1.11L.365,10.21a1.116,1.116,0,0,1,.316-.642L9.931.318a1.078,1.078,0,0,1,1.522,0L13.69,2.555a1.078,1.078,0,0,1,0,1.522l-9.25,9.25a1.115,1.115,0,0,1-.642.316l-2.683.351A.993.993,0,0,1,.985,14Zm.723-3.53-.275,2.1,2.1-.275,8.983-8.983L10.692,1.49Z'
                      />
                      <path
                        id='Path_20783'
                        data-name='Path 20783'
                        d='M283.16,84.283a.682.682,0,0,1-.483-.2l-2.794-2.794a.684.684,0,0,1,.968-.967l2.793,2.793a.684.684,0,0,1-.483,1.167Z'
                        transform='translate(-272.03 -77.929)'
                      />
                    </g>
                  </svg>
                ) : (
                  <Typography
                    variant='h2'
                    sx={[
                      { fontWeight: '900' },
                      timeline < 2
                        ? { color: 'var(--secondary-font-color)' }
                        : { color: 'white' },
                    ]}
                  >
                    {/* {user?.roleId === '3' ? '2' : '3'} */} 2
                  </Typography>
                )}
              </TimelineDot>
              <TimelineConnector
                sx={{ backgroundColor: '#e3e7ed', opacity: '0.7' }}
              />
            </TimelineSeparator>
            <TimelineContent
              sx={{
                flex: 500,
                paddingLeft: mobile ? '8px' : '30px',
                paddingRight: '0px',
                paddingTop: '20px',
                width: '80%',
              }}
            >
              <Stack spacing={1}>
                <Typography variant='h2' sx={{ fontWeight: '900' }}>
                  Add Message
                </Typography>
                {/* <Stack sx={[timeline === 3 ? { display: 'block' } : { display: 'none' }]}><SendMessage /></Stack> */}
                {timeline === 2 && (
                  <Stack>
                    <Box
                      sx={{
                        width: '300px',
                        marginBottom: '8px',
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '8px',
                      }}
                    >
                      {suggestionPage ? (
                        <Stack
                          direction='row'
                          spacing={1}
                          sx={{ alignItems: 'center' }}
                        >
                          <Typography variant='body1'>Sender:</Typography>
                          <SelectOld
                            placeholder='Select Sender'
                            options={[
                              { label: 'Sales Rep', value: 'sr' },
                              { label: `${client.name}`, value: 'brand' },
                            ]}
                            value={sender}
                            setValue={setSender}
                            allowRemoveIcon={false}
                          />
                        </Stack>
                      ) : null}
                      {draftLink ? (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={showInventory}
                              sx={{ marginRight: '5px' }}
                            />
                          }
                          label={
                            <Typography variant='body1'>
                              Show Inventory
                            </Typography>
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setShowInventory(!showInventory);
                          }}
                          sx={{ marginLeft: 0, width: 'fit-content' }}
                        />
                      ) : null}
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isCopyMeSelected}
                            sx={{ marginRight: '5px' }}
                          />
                        }
                        label={
                          <Typography variant='body1'>
                            Send me a copy of this email
                          </Typography>
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setIsCopyMeSelected(!isCopyMeSelected);
                        }}
                        sx={{
                          marginLeft: 0,
                          width: 'fit-content',
                        }}
                      />
                    </Box>
                    <SendMessage
                      editorValue={editorValue}
                      setEditorValue={setEditorValue}
                      message={message}
                      setMessage={(message) => {
                        setMessage(message);
                        if (onFirstTimeLoad) setOnFirstTimeLoad(false);
                      }}
                      subject={subject}
                      setSubject={setSubject}
                      setSubjectEmptyError={setSubjectEmptyError}
                      subjectEmptyError={subjectEmptyError}
                      showSubjectField={true}
                      showPdfTitle={orderLink ? true : false}
                      messageEmptyError={messageEmptyError}
                      setMessageEmptyError={setMessageEmptyError}
                      onFirstTimeLoad={onFirstTimeLoad}
                    />
                    {suggestionPage || draftLink ? (
                      <Stack spacing={1} sx={{ marginTop: '8px' }}>
                        <Stack
                          direction='row'
                          alignItems='center'
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            rowGap: '8px',
                            columnGap: '16px',
                          }}
                        >
                          <LoadingButton
                            loading={uploadFileLoader}
                            disabled={
                              uploadFileLoader || attachedFileCount >= fileLimit
                            }
                            startIcon={
                              <AttachFileOutlinedIcon
                                color='#4D4663'
                                style={{
                                  transform: 'scaleX(-1) rotate(-45deg)', // Adjust the angle as needed
                                  // color: '#4D4663',
                                  color:
                                    uploadFileLoader ||
                                    attachedFileCount >= fileLimit
                                      ? 'rgba(0, 0, 0, 0.26)'
                                      : 'var(--primary-font-color)',
                                }}
                              />
                            }
                            size='small'
                            sx={{
                              width: 'fit-content',
                              border: '1px solid var(--primary-border-color)',
                              borderRadius: '4px',
                              padding: '8px 12px',
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              document.getElementById('uploadFiles').click();
                            }}
                          >
                            <Typography
                              variant='body1'
                              sx={{
                                marginLeft: '6px',
                                color:
                                  uploadFileLoader ||
                                  attachedFileCount >= fileLimit
                                    ? 'rgba(0, 0, 0, 0.26)'
                                    : 'var(--primary-font-color)',
                              }}
                            >
                              Attach Files
                            </Typography>
                          </LoadingButton>
                          <LoadingButton
                            loading={uploadImageLoader}
                            disabled={uploadImageLoader || hasBannerImage}
                            startIcon={
                              <InsertPhotoOutlinedIcon
                                style={{
                                  color:
                                    uploadImageLoader || hasBannerImage
                                      ? 'rgba(0, 0, 0, 0.26)'
                                      : 'var(--primary-font-color)',
                                }}
                              />
                            }
                            size='small'
                            sx={{
                              width: 'fit-content',
                              border: '1px solid var(--primary-border-color)',
                              borderRadius: '4px',
                              padding: '8px 12px',
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              document.getElementById('files').click();
                            }}
                          >
                            <Typography
                              variant='body1'
                              sx={{
                                marginLeft: '6px',
                                color:
                                  uploadImageLoader || hasBannerImage
                                    ? 'rgba(0, 0, 0, 0.26)'
                                    : 'var(--primary-font-color)',
                              }}
                            >
                              Insert Banner Image
                            </Typography>
                          </LoadingButton>
                          {Array.isArray(selectedFiles) &&
                          selectedFiles?.length == 0 ? (
                            <Typography variant='body2'>
                              No File Chosen
                            </Typography>
                          ) : (
                            Array.isArray(selectedFiles) &&
                            selectedFiles?.map((file, i) => (
                              <Chip
                                key={`file_${file?.id}`}
                                label={
                                  <Stack
                                    direction='row'
                                    spacing={0.5}
                                    alignItems='center'
                                  >
                                    {file?.filetype == 'file' ? (
                                      <AttachFileOutlinedIcon
                                        style={{
                                          transform:
                                            'scaleX(-1) rotate(-45deg)', // Adjust the angle as needed
                                          color: 'primary.main !important',
                                          width: 'auto',
                                          height: '18px',
                                        }}
                                      />
                                    ) : (
                                      <InsertPhotoOutlinedIcon
                                        style={{
                                          color: 'primary.main !important',
                                          width: 'auto',
                                          height: '18px',
                                        }}
                                      />
                                    )}

                                    <Typography
                                      variant='body1'
                                      color='primary.main'
                                    >
                                      {file?.actual_filename}
                                    </Typography>
                                  </Stack>
                                }
                                variant='outlined'
                                onDelete={async () => {
                                  let formData = new FormData();

                                  if (draftLink) {
                                    formData.append('delete_imgfile', true);
                                    formData.append(
                                      'worksheet_id',
                                      draftLinkPayloadData?.worksheetId
                                    );
                                    formData.append(
                                      'worksheet_collection_id',
                                      worksheetCollectionId
                                    );
                                    formData.append('type', 'proposal');
                                    formData.append('id', file?.id);
                                  }
                                  if (suggestionPage) {
                                    if (file?.filetype == 'file')
                                      formData.append('delete_files', true);
                                    else
                                      formData.append('delete_imgfile', true);
                                    formData.append(
                                      'suggestion_id',
                                      suggestionEmailpayloadData?.suggestionId
                                    );
                                    formData.append('type', 'proposal');
                                    formData.append('id', file?.id);
                                  }

                                  let res = await uploadImage(formData, false);
                                  if (res) {
                                    // to do update the data from api
                                    setSelectedFiles(res?.data?.data ?? []);
                                  }
                                }}
                                sx={{
                                  marginBottom: '5px',
                                  fontWeight: '700',
                                  marginRight: '8px',
                                  width: 'fit-content',
                                  borderRadius: '16px',
                                  height: '32px',
                                  backgroundColor: '#F2F2F2',
                                  borderColor: 'var(--secondary-border-color)',
                                  color: 'primary.main',
                                  '& .MuiSvgIcon-root': {
                                    color: 'primary.main',
                                  },
                                }}
                              />
                            ))
                          )}

                          <input
                            id='files'
                            type='file'
                            accept='.jpg, .jpeg, .png,'
                            hidden
                            onChange={async (e) => {
                              let file = e.target.files[0];
                              if (!file || file.length === 0) {
                                return; // Exit if no files are selected
                              }
                              setUploadImageLoader(true);
                              let formData = new FormData();

                              if (draftLink) {
                                formData.append('upload_imgfile', true);
                                formData.append(
                                  'worksheet_id',
                                  draftLinkPayloadData?.worksheetId
                                );
                                formData.append(
                                  'worksheet_collection_id',
                                  draftLinkPayloadData?.worksheetCollectionId
                                );
                                formData.append('type', 'proposal');
                                formData.append('email_imgfile', file);
                              }
                              if (suggestionPage) {
                                formData.append('upload_imgfile', true);
                                formData.append(
                                  'suggestion_id',
                                  suggestionEmailpayloadData?.suggestionId
                                );
                                formData.append('type', 'proposal');
                                formData.append('email_imgfile', file);
                              }
                              let res = await uploadImage(formData, true);
                              if (res) {
                                setSelectedFiles(res?.data?.data ?? []);
                                setUploadImageLoader(false);
                              } else {
                                setUploadImageLoader(false);
                              }
                            }}
                          ></input>
                          {/* multiple files input component */}
                          <input
                            id='uploadFiles'
                            type='file'
                            multiple
                            accept='.pdf, .xls, .xlsx, .csv, .doc, .docx, .jpg, .jpeg, .png, .ppt, .pptx, .zip'
                            hidden
                            onChange={async (e) => {
                              let file = e.target.files;

                              if (!file || file.length === 0) {
                                return; // Exit if no files are selected
                              }
                              if (
                                file?.length + attachedFileCount >
                                fileLimit
                              ) {
                                setIsError({
                                  open: true,
                                  message: `A maximum of ${fileLimit} files can be uploaded.`,
                                });
                                return;
                              }
                              if (
                                Array.from(file)?.some(
                                  (file) => file?.size > 5 * 1024 * 1024
                                )
                              ) {
                                setIsError({
                                  open: true,
                                  message:
                                    'Each file must not exceed 5MB in size',
                                });
                                return;
                              }

                              setUploadFileLoader(true);
                              let formData = new FormData();
                              if (draftLink) {
                                formData.append('upload_files', true);
                                formData.append(
                                  'worksheet_id',
                                  draftLinkPayloadData?.worksheetId
                                );
                                formData.append(
                                  'worksheet_collection_id',
                                  draftLinkPayloadData?.worksheetCollectionId
                                );
                                formData.append('type', 'proposal');
                                for (let i = 0; i < file.length; i++) {
                                  formData.append('files[]', file[i]);
                                }
                              }
                              if (suggestionPage) {
                                formData.append('upload_files', true);
                                formData.append(
                                  'suggestion_id',
                                  suggestionEmailpayloadData?.suggestionId
                                );
                                formData.append('type', 'suggestion');

                                for (let i = 0; i < file.length; i++) {
                                  formData.append('files[]', file[i]);
                                }
                              }
                              let res = await uploadImage(formData, true);
                              if (res) {
                                setSelectedFiles(res?.data?.data ?? []);
                                setUploadFileLoader(false);
                              } else {
                                setUploadFileLoader(false);
                              }
                            }}
                          ></input>
                        </Stack>
                        <Stack
                          direction='row'
                          sx={{
                            backgroundColor: '#FFF2DD',
                            padding: '11px 12px',
                            alignItems: 'center',
                            borderRadius: '4px',
                          }}
                          spacing={0.8}
                        >
                          <InformationIcon
                            sx={{ height: '12px', width: '12px' }}
                            fill='#8D5600'
                          />

                          <Typography variant='body1' sx={{ color: '#8D5600' }}>
                            Each file must be no larger than 5 MB. Supported
                            file types include: PDF, XLS, XLSX, CSV, DOC, DOCX,
                            JPG, PNG, PPT, and ZIP. You may add up to 3
                            attachments.
                          </Typography>
                        </Stack>
                      </Stack>
                    ) : null}
                  </Stack>
                )}
              </Stack>
            </TimelineContent>
          </TimelineItem>

          {/* second item ends */}
        </Timeline>
        <SendEmailActions
          actions={(() => {
            if (timeline > 1) {
              if (timeline === 2) {
                return [back, preview];
              }
              return [back, nextStep];
            } else {
              return [nextStep];
            }
          })()}
          emailSentText={`Email will be sent to ${totalBuyersSelected} ${
            totalBuyersSelected > 1 ? 'buyers' : 'buyer'
          } and ${selectedAssociates?.length} ${
            selectedAssociates?.length > 1 ? 'Sales Reps' : 'Sales Rep'
          }`}
        />
      </Stack>
      {showPreview ? (
        <Review
          open={showPreview}
          setOpen={(bool) => {
            const htmlRegex = /<[^>]*>/;
            htmlRegex.test(message)
              ? setOnFirstTimeLoad(false)
              : setOnFirstTimeLoad(true);

            setShowPreview(bool);
          }}
          subject={previewSubject == '' ? subject : previewSubject}
          message={previewMessage == '' ? message : previewMessage}
          selectedBuyers={selectedBuyers}
          selectedAssociates={selectedAssociates}
          uploadImagefunc={(file) => {
            let formData = new FormData();
            if (draftLink) {
              formData.append('upload_imgfile', true);
              formData.append('worksheet_id', worksheetId);
              formData.append('worksheet_collection_id', worksheetCollectionId);
              formData.append('type', 'proposal');
              formData.append('email_imgfile', file);
            }
            if (suggestionPage) {
              formData.append('upload_imgfile', true);
              formData.append('suggestion_id', suggestionId);
              formData.append('type', 'proposal');
              formData.append('email_imgfile', file);
            }
            uploadImage(formData);
          }}
          setSelectedBuyers={setSelectedBuyers}
          setSelectedAssociates={setSelectedAssociates}
          totalBuyersSelected={totalBuyersSelected}
          uploadedFiles={selectedFiles}
          sendButton={sendLink}
        />
      ) : null}
    </Page>
  );
}
SendEmail.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  worksheetId: PropTypes.string,
  worksheetCollectionId: PropTypes.string,
  suggestionId: PropTypes.string,
  orderLink: PropTypes.string,
};

SendEmail.defaultProps = {
  open: false,
  setOpen: () => {},
  worksheetCollectionId: '',
  worksheetId: '',
  suggestionId: '',
  orderLink: '',
};
