import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { customerSupportDataSelector } from 'redux/selectors';
import { defaultFilterState } from './constant';
import { useUpdateURLParams } from 'common-hooks/update-query-params-hooks';
import { useURLSearchParams } from 'common-hooks/url-params-listener-hooks';

export default function useFilterOptions({ applyFilters }) {
  // State to manage all filters collectively
  const [filters, setFilters] = useState(defaultFilterState);
  const supportData = useSelector(customerSupportDataSelector);
  const { updateURLParams } = useUpdateURLParams();
  //state to manage apply filter modal
  const [showApplyFiltersModal, setShowApplyFiltersModal] = useState(false);
  const {
    retailerAlphabetValue = '',
    retailersFiltersCount = '',
    bFilterCount = '',
    gFilterCount = '',
    isGroup = false,
  } = useURLSearchParams();
  // Match the "retailers" route
  const isRetailersPage = useMatch('/my-customer/retailers');

  //state to manage filter count
  let filterCount;
  if (isGroup) filterCount = gFilterCount;
  else if (isRetailersPage) filterCount = retailersFiltersCount;
  else filterCount = bFilterCount;

  // Handles form submission for applying or resetting filters
  const handleSubmit = ({ filterString }) => {
    // Filters out empty values and constructs the applied filters object
    const appliedFilters = Object.entries(filterString)
      .filter(([key, value]) =>
        Array.isArray(value) ? value.length > 0 : value
      )
      .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

    // Calculates the total count of applied filters
    const filtersCount = Object.entries(appliedFilters)
      .filter((item) => item[0] !== 'showBuyers')
      .reduce(
        (count, value) => count + (Array.isArray(value) ? value.length : 1),
        0
      );
    applyFilters(JSON.stringify(appliedFilters), filtersCount);
  };
  return {
    showApplyFiltersModal,
    setShowApplyFiltersModal,
    filterCount,
    supportData,
    filters,
    setFilters,
    updateURLParams,
    retailerAlphabetValue,
    handleSubmit,
  };
}
