export default {
  clientInfo: 'clientInfo',
  clientPublicInfo: 'clientPublicInfo',
  clientOptions: 'clientOptions',
  login: 'login',
  newRequest: 'newRequest',
  forgotUsername: 'forgetUsername',
  resetPassword: 'resetPassword',
  forms: 'forms',
  lineSheets: 'lineSheets',
  smu: 'Smu',
  marketing: 'market',
  resource: 'resource',
  welcomeBar: 'welcomeBar',
  departments: 'departments',
  departmentCatagory: (id) => `department/${id}`,
  deliveries: 'deliveries',
  categories: 'categories',
  dashboardData: 'dashboard/',
  customerSupportData: 'customerSupportData',
  addEditRetailer: 'retailer',
  newAddBuyerRequest: 'buyer',
  editBuyerRequest: 'buyerUpdate',
  newPaymentTypeRequest: 'paymentType',
  editPaymentTypeRequest: (id) => `paymentType/${id}`,
  newAddBillingRequest: 'billingAddress',
  editBillingRequest: (id) => `billingAddress/${id}`,
  newShippingRequest: 'shippingAddress',
  editShippingRequest: (id) => `shippingAddress/${id}`,
  editRetailerRequest: (id) => `retailer/${id}`,
  userInfo: 'userInfo',
  myRetailers: 'myRetailers',
  declineRetailerRequest: (id) => `declineRetailer/${id}`,
  retailer: (id) => `retailer/${id}`,
  deleteRetailers: (id) => `/retailer/${id}`,
  deleteBillingAddress: (id) => `/billingAddress/${id}`,
  deleteShippingAddress: (id) => `/shippingAddress/${id}`,
  deletePaymentType: (id) => `/paymentType/${id}`,
  deleteBuyer: (id) => `/buyer/${id}`,
  confirmDeleteRetailer: (id) => `/confirmDeleteRetailer/${id}`,
  confirmDeleteBuyerAddress: (id) => `/confirmDeleteBuyer/${id}`,
  confirmDeleteBillingAddress: (id) => `/confirmDeleteBilling/${id}`,
  confirmDeleteShippingAddress: (id) => `/confirmDeleteShipping/${id}`,
  retailerDetail: (id) => `retailer/${id}`,
  retailerNotes: 'notes',
  editRetailerNotes: (id) => `notes/${id}`,
  deleteRetailerNotes: (id) => `notes/${id}`,
  buyerList: 'buyers',
  groupList: 'groups',
  checkGroupName: 'checkGroupName',
  deleteGroup: (id) => `/group/${id}`,
  editGroup: (id) => `groups/${id}`,
  myTemplates: 'listTemplates',
  checkLegalName: 'checkLegalName',
  checkBuyerUserName: 'checkBuyerUsername',
  checkBillingIdOrCode: 'checkBillingCode',
  checkShippingIdOrCode: 'checkShippingCode',
  headerMenu: 'headerMenu',
  lookBooks: 'lookbooks',
  lookByLookBooks: (id) => `lookByLookBooks/${id}`,
  lookBookItemList: 'lookBookItemList',
  editProfile: (id) => `/userInfo/${id}`,
  changePassword: `/changePassword`,
  getLinkedAccounts: 'linkedAccounts',
  confirmLinkedAccounts: (id) => `confirmLinkedAccount/${id}`,
  unLinkAccount: (id) => `unlinkAccount/${id}`,
  linkedAccount: 'linkedAccounts',
  tosUserData: 'userTermsOfService',
  clientBanner: 'clientBanner',
  myAttacheReports: 'reportsListing',
  myAttacheReportFilters: 'reports/reportFilters',
  myAttacheReportData: 'reports/reportData',
  myAttacheExportReportData: 'reports/exportReportData',
  myAttacheReportFiltersCompare: 'reports/getCollectionAndCompareLists',
  termsConditions: (id) => `termsConditions/${id}`,
  checkToken: 'checkToken',
  verifyToken: 'verifyToken',
  orderListing: 'order',
  fulfilmentListing: 'order/orderFulfillment',
  orderImport: 'orderImport',
  mapOrderData: 'mapOrderData',
  submitMapData: 'submitMapData',
  finalSubmissionOrderImport: 'finalSubmissionOrderImport',
  validateOrderData: 'validateOrderData',
  addUpdateMapData: 'addUpdateMapData',
  setupSheetFinalSubmission: 'setupSheetFinalSubmission',
  orderDetails: (id) => `order/details?orderId=${id}`,
  erpErrorLogs: (id) => `order/getErpErrorLogs/${id}`,
  orderDetailsPage: (id, type) => `order/getOrderDetails/${id}`,
  orderActions: (id) => `order/getOrderActions/${id}`,
  processOrder: `order/processOrderAction`,
  collectPayment: 'order/collectPayment',
  processPaymentOrder: 'order/processPaymentOrder',
  paymentLogs: 'order/paymentLogs',
  exportOrderListSpreadsheet: 'order/exportOrderListSpreadsheet',
  displayWorksheetTabs: (id) =>
    `displayWorksheetTabs?worksheet_collection_id=${id}`,
  getCheckoutQuestion: (id) => `getCheckoutQuestion/${id}`,
  worksheetHangtags: (id) => `worksheetHangtags?worksheetCollectionId=${id}`,
  batchOrdersData: 'order/getBatchOrderData',
  batchOrdersDataProcess: (payload) =>
    `order/processBatchOrderData?action=${payload}`,
  generateBatchSpreadsheet: 'generateBatchSpreadsheet',
  myRetailer: (id) => `myRetailers/${id}`,
  productListing: (queryParams) => `product/listing?${queryParams}`,
  productLeftNavBar: (queryParams) => `product/leftCatalogNav?${queryParams}`,
  updateProfilePic: 'updateProfilePic',
  buyerDetails: (id) => `buyers/${id}`,
  activeDraftSuggestion: 'getActiveDraftSuggestion',
  getCatalogFilters: 'product/getCatalogFilters',
  addGridItemToDraft: 'addGridItemToDraft',
  removeDraftItemGrid: 'removeDraftItemFromCatalogGrid',
  singleItemSuggest: 'product/singleSuggest',
  suggestAll: 'product/suggestAll',
  getSortOptionsCatalog: 'product/getSortOptionsCatalog',
  companyProfile: 'companyProfile ',
  helpVideos: 'helpVideos',
  reportIssue: 'reportIssue',
  contactForm: 'contactForm',
  updateSortOptionsCatalog: 'product/updateSortOptionsCatalog',
  updateSortOptionsWarehouse: 'product/updateSortOptionsWarehouse',
  updateSortOptionsPrice: 'product/updateSortOptionsPrice',
  getAdvanceSearchFilters: 'product/getAdvanceSearchFilters',
  supportEmail: 'supportEmail',
  addItemToDraft: 'addItemToDraft',
  setProductQuantity: 'setProductQuantity',
  createProductPdf: 'product/createProductPdf',
  loadStats: 'product/loadStats',
  pdfOptions: 'product/pdfOptions',
  notifications: 'notifications',
  notificationViewdAll: 'notification/viewedAll',
  notificationDismissAll: 'notification/dismiss',
  notificationViewed: (id) => `notification/viewed/${id}`,
  buyerRequest: `buyerRequest`,
  buyerRequestNotificationInfo: (id) =>
    `buyerRequestNotificationInfo?notificationId=${id}`,
  declineBuyerRequestFromNotification: (id) =>
    `declineBuyerRequestFromNotification/${id}`,
  buyerEditRequestNotificationInfo: (id) =>
    `buyerEditRequestNotificationInfo?notificationId=${id}`,
  getCatalogLinkName: 'getCatalogLinkName',
  updateDraftItem: 'updateDraftItem',
  addAmazonwarehouse: 'addAmazonwarehouse',
  editAmazonwarehouse: (id) => `editAmazonwarehouse?locationCode=${id}`,
  deleteInventory: (id) => `deleteInventory/${id}`,
  addShipViaConversion: `addShipViaConversion`,
  updateShipViaConversion: (id) => `updateShipViaConversion?id=${id}`,
  deleteShipViaConversion: (id) => `deleteShipViaConversion?id=${id}`,
  productDetailsById: (id, wsId) =>
    `product/modalView?productId=${id}&source=modal&worksheet_collection_id=${wsId}`,
  approveBuyerUserProfileEditRequest: (id) =>
    `approveBuyerUserProfileEditRequest/${id}`,
  updateUserSitePrefs: 'updateSitePrefs',
  checkNewNotificationExists: 'checkNewNotificationExists',
  getBuyerAddEditRequestNotifications: 'getBuyerAddEditRequestNotifications ',
  paymentType: (id) => `paymentType/${id}`,
  faqs: 'faqs ',
  sendOrdersToEdi: 'sendOrdersToEdi',
  sendRequestInfoEmail: 'product/sendRequestInfoEmail',
  helpTour: 'helpTour',
  updateTourStatus: (id) => `updateTourStatus/${id}`,
  sendPaymentRequestEmail: 'order/sendPaymentRequestEmail',
  errorEmail: 'errorEmail',
  updateNotificationStatus: (id) =>
    `updateMobileResponsiveNotificationStatus/${id}`,
  amazonVendorOrderInvoiceData: (id) =>
    `getAmazonVendorOrderInvoiceData?order_num=${id}&type=fetch`,
  homePageProducts: (queryParams) => `home/homePageProducts?${queryParams}`,
  draftBuyerList: 'draftBuyerList',
  userNotifications: 'dashboard/getUserNotifications',
  latestOrders: 'dashboard/latestOrders',
  topSharedDrafts: '/dashboard/topSharedDrafts',
  topSuggestions: '/dashboard/topSuggestions',
  getProductCollectionList: '/getProductCollectionList',
  buyerLocations: '/dashboard/buyerLocations',
  buyerOrderData: (id) => `/dashboard/buyerOrderData/${id}`,
  getSuggestionBannerImages: (id) =>
    `/getSuggestionBannerImages?suggestionId=${id}`,
  removeBannerImage: 'removeBannerImage',
  getSuggestionShareSummary: (id) => `getSuggestionSharedSummary/${id}`,
  getSharedSuggestions: 'sharedSuggestionList',
  suggestionList: 'suggestionList',
};
